<template>
  <div class="price-setting">
    <div>
      <div cols="4">
        <div class="tw-flex">
          <p class="text-header">Price Setting</p>
          <font-awesome-icon
            icon="pen"
            class="pointer ml-2 tw-mt-1"
            @click="modalShow = true"
          />
        </div>
      </div>
    </div>
    <div>
      <div class="tw-grid tw-gap-3 tw-grid-cols-2">
        <div class="tw-col-span-2 text-info">
          Client code: {{ data.clientCode || "-" }}
        </div>
        <div>
          <div class="text-info">Latitude</div>
          <div>{{ data.latitude || "-" }}</div>
        </div>
        <div>
          <div class="text-info">Longitude</div>
          <div>{{ data.longitude || "-" }}</div>
        </div>
        <div>
          <div class="text-info">ระยะทางมากที่สุดที่รองรับ</div>
          <div>{{ data.maxKilometerSupport || "-" }} {{ priceUnit }}</div>
        </div>
        <div>
          <div class="text-info">ราคาทุกๆ ({{ priceUnit }})</div>
          <div>{{ data.averageKilometer || "-" }} {{ priceUnit }}</div>
        </div>
      </div>
      <PriceTable
        :fields="fields"
        :isBusy="isBusy"
        :isLoading="isLoading"
        :items="data.pricePerKilometer"
        :data="data"
        :pricePerKilometer="pricePerKilometer"
        :v="$v"
        @addPrice="addPrice"
        @removePrice="removePrice"
      />
    </div>

    <div class="tw-mt-6 tw-flex tw-justify-end">
      <b-button class="btn-filter save" :disabled="isLoading" @click="savePrice"
        >Save
        <b-spinner
          class="m-0 ml-1"
          label="Spinning"
          small
          v-if="isLoading"
        ></b-spinner
      ></b-button>
    </div>

    <ModalAlertText :textModal="textModal" :arrModal="arrStatus" />
    <ModalPriceSetting
      :modalShow="modalShow"
      @hideModal="hideModal"
      @okModal="okModal"
      :data="data"
      :priceUnit="priceUnit"
    />
  </div>
</template>

<script>
import { required, minValue } from "vuelidate/lib/validators";
import PriceTable from "./component/PriceTable.vue";
import ModalAlertText from "@/components/modal/ModalAlertText.vue";
import ModalPriceSetting from "./component/ModalPriceSetting.vue";
export default {
  components: { PriceTable, ModalAlertText, ModalPriceSetting },
  data() {
    return {
      data: {
        clientCode: "",
        latitude: "",
        longitude: "",
        maxKilometerSupport: 0,
        averageKilometer: 0,
        pricePerKilometer: [],
      },
      isLoading: false,
      pricePerKilometer: {
        startKilometer: 0,
        endKilometer: 0,
        priceFirstKilometer: 0,
        pricePerKilometer: 0,
      },
      priceUnit: "Km",

      modalShow: false,

      // state modal
      textModal: "",
      arrStatus: [],

      // state table
      fields: [
        {
          key: "startKilometer",
          label: "ระยะเริ่มต้น",
          class: "",
        },
        {
          key: "endKilometer",
          label: "ระยะเริ่มต้นสิ้นสุด",
          class: "",
        },
        {
          key: "priceFirstKilometer",
          label: "ราคา",
          class: "",
        },
        { key: "actions", label: "Action" },
      ],
      isBusy: false,
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
    };
  },
  validations() {
    return {
      data: {
        pricePerKilometer: {
          $each: {
            priceFirstKilometer: { required, minValue: minValue(1) },
          },
        },
      },
      pricePerKilometer: {
        pricePerKilometer: { required, minValue: minValue(1) },
      },
    };
  },
  async mounted() {
    await this.getPriceSettingDetail();
  },
  methods: {
    async getPriceSettingDetail() {
      this.isBusy = true;
      const data = await this.$services.pricesetting.getPriceSettingDetail();

      if (data.result == 1) {
        this.pricePerKilometer = {
          ...data.detail.pricePerKilometer[
            data.detail.pricePerKilometer.length - 1
          ],
        };
        this.data = {
          ...data.detail,
          clientCode: data.detail.branchCode,
          pricePerKilometer: data.detail.pricePerKilometer.filter(
            (el) => el.endKilometer
          ),
        };
      }

      this.isBusy = false;
    },
    getLastPriceItem(items) {
      if (!items.length) return null;
      const lastIndex = items.length - 1;
      const lastItem = items[lastIndex];
      return lastItem;
    },
    addPrice() {
      const lastItem = this.getLastPriceItem(this.data.pricePerKilometer);
      const lastEndKilometer = lastItem?.endKilometer || 0;
      const newItem = {
        startKilometer: lastEndKilometer + 1,
        endKilometer: lastEndKilometer + this.data.averageKilometer,
        priceFirstKilometer: 0,
        pricePerKilometer: 0,
      };
      this.data.pricePerKilometer.push(newItem);
      this.setLastPriceItem(newItem);
    },
    removePrice(index) {
      if (this.isLoading) return;
      this.data.pricePerKilometer.splice(index);
      const lastItem = this.getLastPriceItem(this.data.pricePerKilometer);
      this.setLastPriceItem(lastItem);
    },
    hideModal() {
      this.modalShow = false;
    },
    okModal(val) {
      let pricePerKilometer = this.data.pricePerKilometer;
      if (
        val.averageKilometer != this.data.averageKilometer &&
        this.data.pricePerKilometer.length > 0
      ) {
        for (
          let index = 0;
          index < this.data.pricePerKilometer.length;
          index++
        ) {
          if (index === 0) {
            pricePerKilometer[index].endKilometer = val.averageKilometer;
          } else {
            pricePerKilometer[index].startKilometer =
              pricePerKilometer[index - 1].endKilometer + 1;
            pricePerKilometer[index].endKilometer =
              pricePerKilometer[index].startKilometer + val.averageKilometer;
          }
        }
      }

      this.data = val;
      this.data.pricePerKilometer = pricePerKilometer;
      this.pricePerKilometer = {
        ...pricePerKilometer[pricePerKilometer.length - 1],
        endKilometer: null,
        startKilometer:
          pricePerKilometer[pricePerKilometer.length - 1].endKilometer,
      };
    },
    setLastPriceItem(item) {
      this.pricePerKilometer = {
        startKilometer: item?.endKilometer || 0,
        endKilometer: null,
        priceFirstKilometer: item?.priceFirstKilometer || 0,
        pricePerKilometer: item?.pricePerKilometer || 0,
      };
    },
    savePrice() {
      this.$v.$touch();

      if (this.$v.$error) return;
      this.isLoading = true;
      const lastItem = this.getLastPriceItem(this.data.pricePerKilometer);
      this.pricePerKilometer.priceFirstKilometer = lastItem.priceFirstKilometer;
      const payload = {
        ...this.data,
        pricePerKilometer: [
          ...this.data.pricePerKilometer,
          this.pricePerKilometer,
        ],
      };

      this.submitPrice(payload);
    },
    async submitPrice(payload) {
      const respone = await this.$services.pricesetting.savePriceSetting(
        payload
      );
      let text = "";
      if (respone.result === 1) {
        text = respone.detail.message;
      } else {
        text = respone.message;
      }
      this.isLoading = false;

      this.openModalAlertText(text);
      this.$v.$reset();
      this.getPriceSettingDetail();
    },
    openModalAlertText(text) {
      this.textModal = text;
      this.$bvModal.show("modal-alert-text");
    },
  },
};
</script>

<style lang="scss" scoped>
.price-setting {
  .text-header {
    color: #092d53;
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
  }

  :deep(.text-info) {
    color: var(--font-main-color) !important;
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: bold;
    width: 100%;
  }

  .btn-filter {
    width: 100%;
    max-width: 150px;

    &.cancel {
      border: none;
      background: #606060;
      color: white !important;
    }

    &.save {
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--secondary-color);
      border: none;
      color: white !important;
    }
    &.save:hover {
      background: var(--secondary-color);
      cursor: pointer;
    }
    &.cancel:hover {
      background: #606060;
      color: white !important;

      cursor: pointer;
    }
  }
}
</style>
