<template>
  <b-modal
    v-model="showModal"
    @cancel="$emit('hideModal')"
    @ok="handleClickSave"
    title="Price Setting"
  >
    <div class="tw-grid tw-gap-3 tw-grid-cols-2">
      <div class="tw-col-span-2 text-info">
        <p class="tw-text-black tw-text-md tw-font-bold">Client code: {{ editData.clientCode || "-" }}</p>
      </div>
      <div>
        <InputText
          v-model="editData.latitude"
          class="input mb-0"
          textFloat="Latitude"
          placeholder="Latitude"
          type="number"
          name="latitude"
          :v="$v.editData.latitude"
          :isValidate="$v.editData.latitude.$error"
          isRequired
        />
      </div>
      <div>
        <InputText
          v-model="editData.longitude"
          class="input mb-0"
          textFloat="Longitude"
          placeholder="Longitude"
          type="number"
          name="longitude"
          :v="$v.editData.longitude"
          :isValidate="$v.editData.longitude.$error"
          isRequired
        />
      </div>
      <div>
        <InputText
          v-model="editData.maxKilometerSupport"
          class="input mb-0"
          :textFloat="`ระยะทางมากที่สุดที่รองรับ / ${priceUnit}`"
          :placeholder="`ระยะทางมากที่สุดที่รองรับ / ${priceUnit}`"
          type="number"
          name="maxKilometerSupport"
          :v="$v.editData.maxKilometerSupport"
          :isValidate="$v.editData.maxKilometerSupport.$error"
          isRequired
        />
      </div>
      <div>
        <InputText
          v-model="editData.averageKilometer"
          class="input mb-0"
          :textFloat="`ราคาทุกๆ / ${priceUnit}`"
          :placeholder="`ราคาทุกๆ / ${priceUnit}`"
          type="number"
          name="averageKilometer"
          :v="$v.editData.averageKilometer"
          :isValidate="$v.editData.averageKilometer.$error"
          isRequired
        />
      </div></div
  ></b-modal>
</template>

<script>
import { required, minValue } from "vuelidate/lib/validators";
export default {
  name: "ModalPriceSetting",
  data() {
    return {
      showModal: this.modalShow,
      editData: {},
    };
  },
  props: {
    modalShow: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      required: true,
    },
    priceUnit: {
      type: String,
      required: true,
    },
  },
  validations() {
    return {
      editData: {
        latitude: { required },
        longitude: { required },
        maxKilometerSupport: { required, minValue: minValue(1) },
        averageKilometer: { required, minValue: minValue(1) },
      },
    };
  },
  watch: {
    modalShow(val) {
      this.showModal = val;
    },
    data(val) {
      this.editData = JSON.parse(JSON.stringify(val));
    },
  },
  methods: {
    handleClickSave(bvModalEvt) {
      this.$v.$touch();

      if (this.$v.$error) {
        bvModalEvt.preventDefault();
        return;
      }
      this.$emit("okModal", this.editData);
    },
  },
};
</script>

<style lang="scss" scoped></style>
