<template>
  <div class="tw-flex tw-flex-col tw-gap-4">
    <div class="tw-flex tw-justify-between tw-items-end">
      <div class="text-info">เรทราคา</div>
      <div>
        <b-button
          class="btn-add btn"
          :disabled="isLoading || isDisbled"
          @click="addPrice()"
        >
          เพิ่ม
        </b-button>
      </div>
    </div>

    <b-table
      responsive
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      show-empty
      empty-text="No matching records found"
    >
      <template v-slot:cell(priceFirstKilometer)="{ item, index }">
        <InputText
          v-model="item.priceFirstKilometer"
          class="input mb-0"
          textFloat=""
          placeholder="ราคา"
          type="number"
          name="priceFirstKilometer"
          isRequired
          :v="v.data.pricePerKilometer.$each[index].priceFirstKilometer"
          :isValidate="
            v.data.pricePerKilometer.$each[index].priceFirstKilometer.$error
          "
        />
      </template>
      <template v-slot:cell(actions)="{ item, index }">
        <font-awesome-icon
          class="pointer"
          icon="trash-alt"
          @click="removePrice(index)"
        />
      </template>

      <template v-slot:table-busy>
        <div class="text-center text-black my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">Loading...</strong>
        </div>
      </template>
    </b-table>
    <div
      class="tw-flex tw-items-center tw-gap-2 tw-flex-wrap"
      v-if="items.length"
    >
      หลังจาก
      {{ pricePerKilometer.startKilometer || 0 }}
      กิโลเมตร ราคากิโลเมตรละ
      <InputText
        v-model="pricePerKilometer.pricePerKilometer"
        class="input mb-0"
        textFloat=""
        placeholder="ราคาต่อกิโลเมตร"
        type="text"
        name="name"
        isRequired
        :v="v.pricePerKilometer.pricePerKilometer"
        :isValidate="v.pricePerKilometer.pricePerKilometer.$error"
      />
      บาท
    </div>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
export default {
  components: {
    InputText,
  },
  props: {
    fields: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    isLoading: {
      required: true,
      type: Boolean,
    },
    data: {
      required: true,
      type: Object,
    },
    pricePerKilometer: {
      required: true,
      type: Object,
    },
    v: {
      required: true,
      type: Object,
    },
  },
  computed: {
    isDisbled: function () {
      return this.items.some(
        (el) => el.endKilometer >= this.data.maxKilometerSupport
      );
    },
  },
  methods: {
    removePrice(index) {
      this.$emit("removePrice", index);
    },
    addPrice(obj) {
      this.$emit("addPrice", obj);
    },
  },
};
</script>

<style lang="scss" scoped></style>
